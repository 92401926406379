<template>
    <div>
        <div class="overview-switcher">
            <OverviewButton
                category="cost"
                key="cost"
                :amount="totalCostsSaved"
                :active="overviewMode === 'cost'"
                :improvementsPushed="costSavings.teamCostSavings.total.count"
                @clicked="changeOverviewMode('cost')"
                :period="period"
                :validating="$props.costSavingsValidating"
                :tooltipText="`For reference, your total Opteo subscription cost over this time period was <b>${subscriptionCost}</b>`"
            />
            <OverviewButton
                category="time"
                key="time"
                :amount="totalTimeSaved"
                :active="overviewMode === 'time'"
                @clicked="changeOverviewMode('time')"
                :period="period"
                :validating="$props.timeSavingsValidating"
            />
            <OverviewButton
                category="alerts"
                key="alerts"
                :amount="totalAlertsFound"
                :active="overviewMode === 'alerts'"
                @clicked="changeOverviewMode('alerts')"
                :period="period"
                :validating="$props.criticalAlertsValidating"
            />
        </div>
        <Spacer height="2rem" />
        <!-- Tables -->
        <oTable
            class="impact-statistics-overview-table"
            :headers="tableHeaders"
            :items="overviewTableData"
            :per-page="4"
            :border-radius="24"
            :orderBy="tableOrder"
            order="DESC"
            :key="tableOrder"
        >
            <!-- Improvement Type Header -->
            <template v-slot:header.improvementType>
                <div class="improvement-types-header-cell">
                    Improvement Type
                    <Tooltip
                        :maxWidth="296"
                        :offset="[0, 12]"
                        content="Please note, this table only includes Improvement types that have a specific Cost Saved value."
                    >
                        <InfoIcon class="improvement-types-info" />
                    </Tooltip>
                </div>
            </template>
            <!-- Detailed View Button -->
            <template v-slot:column.seeMore="seeMore">
                <oButton
                    color="white"
                    size="small"
                    class="see-more-button"
                    :disabled="seeMore.value.length < 1"
                    @clicked="openModal(seeMore.value)"
                >
                    Detailed View
                </oButton>
            </template>
            <!-- Skeleton for improvementType cells -->
            <template v-if="currentDataValidating" v-slot:column.improvementType>
                <Skeleton width="12rem" />
            </template>
            <!-- Skeleton for total time saved -->
            <template v-if="currentDataValidating" v-slot:column.totalTimeSaved>
                <Skeleton width="4.5rem" />
            </template>
            <!-- Skeleton for Count cells -->
            <template v-if="currentDataValidating" v-slot:column.count>
                <Skeleton width="2rem" />
            </template>
            <!-- Skeleton for time saved per imp-->
            <template v-if="currentDataValidating" v-slot:column.timeSavedPerImp>
                <Skeleton width="4.5rem" />
            </template>
            <!-- Skeleton for time saved per user-->
            <template v-if="currentDataValidating" v-slot:column.timeSavedPerUser>
                <Skeleton width="4.5rem" />
            </template>
            <!-- Skeleton for averagePerWeek -->
            <template v-if="currentDataValidating" v-slot:column.averagePerWeek>
                <Skeleton width="2.5rem" />
            </template>
            <!-- Skeleton for timeSavedPerAction -->
            <template v-if="currentDataValidating" v-slot:column.timeSavedPerAction>
                <Skeleton width="3.5rem" />
            </template>
            <!-- Skeleton for emailsSent -->
            <template v-if="currentDataValidating" v-slot:column.emailsSent>
                <Skeleton width="2rem" />
            </template>
            <!-- For sorting time correctly -->
            <template v-slot:column.totalTimeSaved="cell">
                {{ formatFromMinutes(cell.value, false) }}
            </template>
            <!-- Date formatting for mostRecent, or skeleton -->
            <template v-slot:column.mostRecent="cell">
                <Skeleton v-if="currentDataValidating" width="7rem" />
                <div v-else>{{ formatDateFromTimestamp(cell.value) }}</div>
            </template>
            <!-- Money cells where applicable -->
            <template v-slot:column.avgSavedPerImp="cell">
                <Money v-if="!currentDataValidating" currency="USD" :value="cell.value" />
                <Skeleton v-else-if="currentDataValidating" width="3rem" />
            </template>
            <template v-slot:column.totalCostSaved="cell">
                <Money v-if="!currentDataValidating" currency="USD" :value="cell.value" />
                <Skeleton v-else-if="currentDataValidating" width="3rem" />
            </template>
            <!--Footer-->
            <template #footer v-if="overviewMode === 'alerts'">
                <div class="impact-statistics-overview-table-footer">
                    <div class="impact-statistics-overview-table-footer-contents">
                        <div style="margin-right: 0.75rem">
                            <CheckIcon style="display: block" />
                        </div>
                        <div v-if="!currentDataValidating">
                            <Text size="f-8">
                                Opteo found <strong>{{ totalAlertsFound }} alerts</strong> across
                                <strong>{{ totalAccounts }} accounts</strong>.
                            </Text>
                        </div>
                        <div v-else-if="currentDataValidating">
                            <Skeleton width="400px" />
                        </div>
                    </div>
                </div>
            </template>
        </oTable>
        <!-- See More Modal -->
        <Modal
            v-model="seeMoreModalOpen"
            :title="modalTitle"
            width="78.5rem"
            @closed="resetNumberOfVisibleRows"
        >
            <template #content>
                <div v-if="overviewMode === 'cost'">
                    <ImpactStatisticsImprovementRow
                        v-for="(row, index) in slicedRows"
                        :improvement="row as unknown as IS.CostSavingOverviewInstance"
                        :class="index === slicedRows.length - 1 ? 'last' : ''"
                        @click="openInNewTab(row)"
                    />
                </div>
                <div v-if="overviewMode === 'alerts'">
                    <ImpactStatisticsAlertRow
                        v-for="(row, index) in slicedRows"
                        :alert="row as unknown as IS.CriticalAlertOverviewInstance"
                        :class="index === slicedRows.length - 1 ? 'last' : ''"
                    />
                </div>
                <div
                    v-if="modalTableRows.length > slicedRows.length"
                    class="show-more-button-wrapper"
                >
                    <oButton color="white" @clicked="showMoreRows">Show More</oButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
// vue
import { computed, ref, PropType } from 'vue'
// frontend
import OverviewButton from '@/components/impactStatistics/OverviewButton.vue'
import useImpactStatistics from '@/composition/impactStatistics/useImpactStatistics'
import useImpactStatsTimeSavings from '@/composition/impactStatistics/useImpactStatsTimeSavings'
import ImpactStatisticsImprovementRow from '@/components/impactStatistics/ImpactStatisticsImprovementRow.vue'
import ImpactStatisticsAlertRow from '@/components/impactStatistics/ImpactStatisticsAlertRow.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import InfoIcon from '@/components/global/InfoIcon.vue'

// components-next
import {
    Text,
    Spacer,
    oTable,
    CheckIcon,
    oButton,
    Money,
    Modal,
    Tooltip,
    useMoney,
} from '@opteo/components-next'
// Types
import { IS } from '@opteo/types'
// lodash
import sortBy from 'lodash-es/sortBy'
import startCase from 'lodash-es/startCase'

// Props
const props = defineProps({
    costSavings: {
        type: Object as PropType<IS.GetCostSavedResponse>,
        required: true,
    },
    timeSavings: {
        type: Object as PropType<IS.GetTimeSavedResponse>,
        required: true,
    },
    criticalAlerts: {
        type: Object as PropType<IS.GetCriticalAlertCountsResponse>,
        required: true,
    },
    period: {
        type: String,
        required: true,
    },
    costSavingsValidating: {
        type: Boolean,
        required: true,
    },
    criticalAlertsValidating: {
        type: Boolean,
        required: true,
    },
    timeSavingsValidating: {
        type: Boolean,
        required: true,
    },
})
// useImpactStatistics
const {
    formatDateFromTimestamp,
    openUrlInNewTab,
    generateImprovementRouterLink,
    generatePauseSpendRouterLink,
    generateAlertRouterLink,
} = useImpactStatistics()
// useImpactStatsTimeSavings functions
const { minutesToDuration, formatFromMinutes } = useImpactStatsTimeSavings()

const overviewMode = ref('cost')

const currentDataValidating = computed(() => {
    if (overviewMode.value === 'cost') return props.costSavingsValidating
    else if (overviewMode.value === 'time') return props.timeSavingsValidating
    else if (overviewMode.value === 'alerts') return props.criticalAlertsValidating
    else return false
})

const changeOverviewMode = (mode: 'cost' | 'time' | 'alerts') => {
    // acceptable values
    const modes = ['cost', 'time', 'alerts']
    if (modes.includes(mode)) {
        overviewMode.value = mode
    }
}

// Totals
const totalCostsSaved = computed(() => {
    return props.costSavings.teamCostSavings.total.monthlyCostSavingsUSD
})
const totalTimeSaved = computed(() => {
    return props.timeSavings.teamTimeSavings.totalMinutesSaved
})
const totalAlertsFound = computed(() => {
    return props.criticalAlerts.teamCriticalAlertCount.totalCriticalAlertCount
})
const totalAccounts = computed(() => {
    return Object.keys(props.criticalAlerts.accountCriticalAlertCounts).length
})

const subscriptionCost = computed(() => {
    return useMoney({ value: props.costSavings.subscriptionCost, currency: 'USD' }).displayValue
        .value
})

// Table data
const costHeaders = [
    { key: 'improvementType', text: 'Improvement Type' },
    { key: 'totalCostSaved', text: 'Cost Saved', sortable: true },
    { key: 'count', text: 'Count', sortable: true },
    { key: 'avgSavedPerImp', text: 'Per Imp.', sortable: true },
    { key: 'seeMore', text: 'Actions' },
]
const timeHeaders = [
    { key: 'actionType', text: 'Action Type' },
    { key: 'totalTimeSaved', text: 'Time Saved', sortable: true },
    { key: 'count', text: 'Count', sortable: true },
    { key: 'timeSavedPerAction', text: 'Per Action', sortable: true },
    { key: 'mostRecent', text: 'Most Recent', sortable: true },
]
const alertHeaders = [
    { key: 'alertType', text: 'Alert Type' },
    { key: 'count', text: 'Count', sortable: true },
    { key: 'emailsSent', text: 'Emails Sent', sortable: true },
    { key: 'mostRecent', text: 'Most Recent', sortable: true },
    { key: 'seeMore', text: 'Actions' },
]
const tableHeaders = computed(() => {
    if (overviewMode.value === 'cost') return costHeaders
    else if (overviewMode.value === 'time') return timeHeaders
    else if (overviewMode.value === 'alerts') return alertHeaders
    else
        throw new Error(
            `Invalid overviewMode value: ${overviewMode.value}. Should be 'cost', 'time', or 'alerts'.`
        )
})

const tableOrder = computed(() => {
    if (overviewMode.value === 'cost') return 'totalCostSaved'
    else if (overviewMode.value === 'time') return 'totalTimeSaved'
    else if (overviewMode.value === 'alerts') return 'count'
})

const overviewTableData = computed(() => {
    // cost
    if (overviewMode.value === 'cost') {
        // Sorted in descending order according to total cost savings
        return props.costSavings.overviewItems
    }
    // time
    else if (overviewMode.value === 'time') {
        return props.timeSavings.overviewItems
    }
    // alerts
    else if (overviewMode.value === 'alerts') {
        return props.criticalAlerts.overviewItems
    } else
        throw new Error(
            `Invalid overviewMode value: ${overviewMode.value}. Should be 'cost', 'time', or 'alerts'.`
        )
})
// Modal
const seeMoreModalOpen = ref(false)
const modalTitle = ref('Breakdown')
function isAlertInstanceType(
    instance: IS.CostSavingOverviewInstance | IS.CriticalAlertOverviewInstance
): instance is IS.CriticalAlertOverviewInstance {
    return (instance as IS.CriticalAlertOverviewInstance).hashAlertId !== undefined
}

const modalTableRows = ref<(IS.CostSavingOverviewInstance | IS.CriticalAlertOverviewInstance)[]>([])

const openInNewTab = (
    instanceData: IS.CostSavingOverviewInstance | IS.CriticalAlertOverviewInstance
) => {
    // Pause Spend
    if (instanceData.type === IS.OverviewItemInstanceType.pauseSpend) {
        // openUrlInNewTab(generatePauseSpendRouterLink({ accountId: instanceData.account.accountId }))
        return
    }
    // Improvements
    else if (
        instanceData.type === IS.OverviewItemInstanceType.improvement &&
        instanceData.improvementId
    ) {
        openUrlInNewTab(
            generateImprovementRouterLink({
                accountId: instanceData.account.accountId,
                improvementId: instanceData.improvementId,
            })
        )
    }

    // Alerts
    else if (isAlertInstanceType(instanceData) && instanceData.hashAlertId) {
        // openUrlInNewTab(generateAlertRouterLink(instanceData.hashAlertId))
        return
    }
}

const openModal = (instances: IS.CostSavingOverviewInstance[]) => {
    // Set the correct modal title
    if (overviewMode.value === 'cost') {
        modalTitle.value = 'Cost Savings Breakdown'
    } else if (overviewMode.value === 'alerts') {
        modalTitle.value = 'Critical Alerts Breakdown'
    }

    modalTableRows.value = instances

    // Open the modal
    seeMoreModalOpen.value = true
}

const numberOfVisibleRows = ref(50)
const slicedRows = computed(() => {
    return modalTableRows.value.slice(0, numberOfVisibleRows.value)
})
const showMoreRows = () => {
    numberOfVisibleRows.value = numberOfVisibleRows.value + 50
}
const resetNumberOfVisibleRows = () => {
    numberOfVisibleRows.value = 50
}
</script>

<!-- Scoped -->
<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.overview-switcher {
    @include flex;
    gap: 2rem;
}

.more-dots-icon-container {
    @include flex;
    width: 100%;
    justify-content: center;
}

.improvement-types-header-cell {
    @include flex;
    @include items-center;
}
.improvement-types-info {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.4375rem;
    @include block;
}

.impact-statistics-overview-table-footer {
    width: 100%;
    @include ph-24;
    @include pv-16;
    border-top: 1px solid;
    @include opteo-border;
    .impact-statistics-overview-table-footer-contents {
        display: flex;
        align-items: center;
        height: 32px;
    }
}
// Modal
.show-more-button-wrapper {
    width: 100%;
    @include flex-center;
    @include mt-24;
}
</style>

<!-- Unscoped -->
<style lang="scss">
@media screen and (max-width: 1279px) {
    .impact-statistics-overview-table td:not(:first-child),
    .impact-statistics-overview-table th:not(:first-child) {
        // background: red;
        padding-left: 1.125rem;
        padding-right: 1.125rem;
    }
    .impact-statistics-overview-table td:last-child {
        padding-right: 1.5rem;
    }
}

@media screen and (min-width: 1280px) {
    .impact-statistics-overview-table td:last-child {
        padding-right: 2.125rem;
    }
}

.impact-statistics-overview-table td {
    padding: 0.875rem 1.5rem;
}
.impact-statistics-overview-table td:first-child {
    width: 100%;
}
.impact-statistics-overview-table td:first-child .cell-contents {
    height: 2.25rem;
    display: flex;
    align-items: center;
}
</style>
